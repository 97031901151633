<template>
  <div id="app">
    <div class="navBar">
      <NavBar></NavBar>
    </div>
    <router-view />
    <div class="footer">
      <Footer></Footer>

    </div>
  </div>
</template> 

<script>
import NavBar from "./views/nav/navbar.vue";
import Footer from "./views/nav/footer.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
  created() {
    console.log('当前版本：v1.0.22_20221107')
    this.isMobile();
  },
  methods: {
    isMobile() {
      if (process.env.NODE_ENV === 'development') {
        console.log('当前为开发环境')
      } else {
        // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        // if (flag) {
        //   window.location.href = 'https://web-mobile.qiantu-iot.com/#/'
        // } else {
          // window.location.href = 'https://www.qiatoo.com/#/'
          window.location.href = 'https://www.qiantu-iot.com/#/'


        // }
      }
    },

  }

};
</script>
<style lang="scss">
#app {
  font-family: "Microsoft YaHei", 微软雅黑, "MicrosoftJhengHei", 华文细黑, STHeiti, MingLiu;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #111111;
  // background: #d3dce6;

  .navBar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
  }

  .footer {
    display: flex;
    justify-content: center;
  }
}
</style>
