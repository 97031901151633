<template>
  <div>
    <video autoplay loop muted class="fillWidth" :style="{ 'height': videoHeight }">
      <source src="../assets/video/home_banner.mp4" type="video/mp4" />
    </video>
  </div>

</template>
<script>
import imgsUrl from "../utils/imgAddress";

export default {
  data() {
    return {
      bannerVideo: imgsUrl.video.home_banner,
      lingluapp: imgsUrl.video.lingluapp,
      xuanchuan: imgsUrl.video.xuanchuan,
      appfm: imgsUrl.video.appfm,
      homefm: imgsUrl.video.homefm,
      videoHeight: 0,
      appPlayShow: true,
      playShow: true,

    }
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll)

    // this.playShow = true;
    this.videoHeight = document.documentElement.clientHeight + 'px',

      // 屏幕自适应
      // 获取屏幕宽高，根据不同屏幕调整视频的宽高自适应大小
      window.onresize = () => {
        window.location.reload();
      }
  },
  methods: {
    handleScroll() {
      let clientHeight = document.documentElement.clientHeight;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      if(scrollTop > clientHeight && scrollTop <= clientHeight *2){
        this.appPlayShow = true;
        console.log('111')
      }else{
        this.appPlayShow = false;
      }
     

  }
}
}
</script>

<style scoped>
.homepage-hero-module,
.video-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}

.video-container {
  z-index: 0;
  object-fit: cover;

  /* position: absolute; */
}

.fillWidth {
  width: 100%;
  object-fit: cover;
  /* background: #fff; */


}
</style>